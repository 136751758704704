package io.daio.pancake.foundations.text

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import io.daio.pancake.foundations.Theme

@Composable
fun LinkText(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Theme.colors.textPrimary,
    textAlign: TextAlign = TextAlign.Start
) {
    BaseText(
        text,
        modifier = modifier,
        textStyle = mainTypography.link.copy(textColor),
        textAlign = textAlign
    )
}

package io.daio.pancake.foundations.text

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import io.daio.pancake.foundations.Theme

@Composable
fun TitleTextScreen(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Theme.colors.textPrimary,
    textAlign: TextAlign = TextAlign.Start
) {
    BaseText(
        text,
        mainTypography.titleScreen.copy(color = textColor),
        textAlign = textAlign,
        modifier = modifier
    )
}

@Composable
fun TitleTextLarge(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Theme.colors.textPrimary,
    textAlign: TextAlign = TextAlign.Start
) {
    BaseText(
        text,
        mainTypography.titleLarge.copy(textColor),
        textAlign = textAlign,
        modifier = modifier
    )
}

@Composable
fun TitleText(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Theme.colors.textPrimary,
    textAlign: TextAlign = TextAlign.Start
) {
    BaseText(
        text,
        mainTypography.titleDefault.copy(textColor),
        textAlign = textAlign,
        modifier = modifier
    )
}

package io.daio.pancake.components.buttons

import androidx.compose.foundation.interaction.InteractionSource
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.height
import androidx.compose.material.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextLarge

@Composable
fun SmallAccentButton(
    text: String,
    modifier: Modifier = Modifier,
    onClick: () -> Unit = {}
) {
    SmallButton(
        text = text, modifier = modifier,
        colors = PancakeButtonDefaults.colors(
            Theme.colors.accent,
            Theme.colors.textOnAccent
        ),
        onClick = onClick
    )
}


/**
 * Creates a [SmallButton].
 *
 * @param text the text to be displayed on the button
 * @param modifier the [Modifier] to be applied to the button
 * @param colors the [ButtonColors] to be used for the button
 * @param onClick the action to be performed when the button is clicked
 */
@Composable
fun SmallButton(
    text: String,
    modifier: Modifier = Modifier,
    colors: ButtonColors = PancakeButtonDefaults.colors(),
    onClick: () -> Unit = {}
) {
    BaseButton(
        text,
        modifier = modifier.height(36.dp),
        buttonColors = colors,
        onClick = onClick
    )
}


@Composable
fun MediumAccentButton(
    text: String,
    modifier: Modifier = Modifier,
    onClick: () -> Unit = {}
) {
    MediumButton(
        text = text, modifier = modifier,
        colors = PancakeButtonDefaults.colors(
            Theme.colors.accent,
            Theme.colors.textOnAccent
        ),
        onClick = onClick
    )
}

@Composable
fun MediumButton(
    text: String,
    modifier: Modifier = Modifier,
    colors: ButtonColors = PancakeButtonDefaults.colors(),
    onClick: () -> Unit = {}
) {
    BaseButton(
        text,
        modifier = modifier.height(48.dp),
        buttonColors = colors,
        onClick = onClick
    )
}


@Composable
fun LargeAccentButton(
    text: String,
    modifier: Modifier = Modifier,
    onClick: () -> Unit = {}
) {
    LargeButton(
        text = text, modifier = modifier,
        colors = PancakeButtonDefaults.colors(
            Theme.colors.accent,
            Theme.colors.textOnAccent
        ),
        onClick = onClick
    )
}

@Composable
fun LargeButton(
    text: String,
    modifier: Modifier = Modifier,
    colors: ButtonColors = PancakeButtonDefaults.colors(),
    onClick: () -> Unit = {}
) {
    BaseButton(
        text,
        buttonColors = colors,
        Modifier.height(56.dp).then(modifier),
        onClick = onClick,
        composable = {
            BodyTextLarge(
                text,
                textColor = Theme.colors.textComponent,
                textAlign = TextAlign.Center
            )
        }
    )
}

object PancakeButtonDefaults {
    @Composable
    fun colors(
        backgroundColor: Color = Theme.colors.interactivePrimary,
        contentColor: Color = Theme.colors.textComponent
    ): ButtonColors = ButtonDefaults.buttonColors(
        backgroundColor = backgroundColor,
        contentColor = contentColor,
        disabledBackgroundColor = backgroundColor.copy(alpha = ContentAlpha.disabled),
        disabledContentColor = contentColor.copy(alpha = ContentAlpha.disabled)
    )
}

@Composable
internal fun BaseButton(
    text: String,
    buttonColors: ButtonColors,
    modifier: Modifier = Modifier,
    contentPadding: PaddingValues = ButtonDefaults.ContentPadding,
    elevation: ButtonElevation? = ButtonDefaults.elevation(
        defaultElevation = 0.dp,
        hoveredElevation = 0.dp,
        disabledElevation = 0.dp,
        pressedElevation = 0.dp,
        focusedElevation = 0.dp
    ),
    composable: @Composable () -> Unit = {
        BodyText(
            text,
            modifier,
            Theme.colors.textComponent,
            textAlign = TextAlign.Center
        )
    },
    onClick: () -> Unit
) {
    Button(
        onClick,
        modifier,
        elevation = elevation,
        shape = Theme.shapes.circle,
        contentPadding = contentPadding,
        colors = buttonColors
    ) {
        composable()
    }
}

private object NoElevationButton : ButtonElevation {
    @Composable
    override fun elevation(enabled: Boolean, interactionSource: InteractionSource): State<Dp> {
        return rememberUpdatedState(0.dp)
    }

}
package io.daio.pancake.foundations.shape

import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.ui.unit.dp

val radiusXS = 1.dp
val radiusS = 2.dp
val radiusM = 4.dp
val radiusL = 8.dp

interface Shapes {
    val extraSmall: RoundedCornerShape
    val small: RoundedCornerShape
    val medium: RoundedCornerShape
    val large: RoundedCornerShape

    /**
     * A convenience extension to get a full circle shape.
     */
    val circle: RoundedCornerShape

    /**
     * A convenience extension to get a shape with no rounding when you want to remove rounded edges.
     */
    val zeroRoundings: RoundedCornerShape
}

data class PancakeShapes(
    override val extraSmall: RoundedCornerShape = RoundedCornerShape(radiusXS),
    override val small: RoundedCornerShape = RoundedCornerShape(radiusS),
    override val medium: RoundedCornerShape = RoundedCornerShape(radiusM),
    override val large: RoundedCornerShape = RoundedCornerShape(radiusL),
) : Shapes {
    /**
     * A convenience extension to get a full circle shape.
     */
    override val circle: RoundedCornerShape = RoundedCornerShape(50)

    /**
     * A convenience extension to get a shape with no rounding when you want to remove rounded edges.
     */
    override val zeroRoundings: RoundedCornerShape = RoundedCornerShape(0.dp)
}

internal val mainShapes by lazy { PancakeShapes() }



// Do not edit directly
// Generated on Fri, 13 Oct 2023 06:39:50 GMT



package io.daio.pancake.foundations.text;

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.*

object FontSize {
  val fontBodyDefault = 14.00.sp
  val fontBodyLarge = 16.00.sp
  val fontLink = 12.00.sp
  val fontTitleDefault = 28.00.sp
  val fontTitleLarge = 32.00.sp
  val fontTitleScreen = 40.00.sp
}

package io.daio.pancake.foundations.text

import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme

@Composable
fun BodyTextLarge(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Theme.colors.textPrimary,
    textAlign: TextAlign = TextAlign.Start
) {
    BaseText(
        text,
        modifier = modifier,
        textStyle = mainTypography.bodyLarge.copy(textColor),
        textAlign = textAlign
    )
}

@Composable
fun BodyText(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Theme.colors.textPrimary,
    textAlign: TextAlign = TextAlign.Start
) {
    BaseText(
        text,
        modifier = modifier,
        textStyle = mainTypography.bodyDefault.merge(textColor),
        textAlign = textAlign
    )
}

package screens

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.ColorPainter
import io.daio.pancake.components.buttons.*
import io.daio.pancake.foundations.text.TitleTextScreen
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier

@Composable
fun ButtonsScreen(modifier: Modifier = Modifier) {
    Stack(
        modifier = modifier
            .fillMaxSize()
            .verticalScroll(
                rememberScrollState()
            )
    ) {
        Tier {
            SmallButton("Small Button")
            MediumButton("Medium Button")
            LargeButton("Large Button")
        }

        Tier {
            SmallAccentButton("Small Accent")
            MediumAccentButton("Medium Accent")
            LargeAccentButton("Large Accent")
        }

        Tier {
            CircularButton("Circular", icon = ColorPainter(Color.Transparent), onClick = {})
            CircularAccentButton("Circular Accent", icon = ColorPainter(Color.Transparent), onClick = {})
        }
    }
}

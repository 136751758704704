package io.daio.pancake.components.bottomsheet

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.padding
import androidx.compose.material.ExperimentalMaterialApi
import androidx.compose.material.ModalBottomSheetState
import androidx.compose.material.ModalBottomSheetValue
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Density
import io.daio.pancake.components.item.SectionHeaderItem
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier

@Composable
fun rememberBottomSheetState(): BottomSheetState {
    val density = LocalDensity.current
    return remember(density) { createBottomSheetState(density) }
}

@OptIn(ExperimentalMaterialApi::class)
fun createBottomSheetState(density: Density): BottomSheetState {
    return BottomSheetState(
        ModalBottomSheetState(ModalBottomSheetValue.Hidden, isSkipHalfExpanded = false, density = density)
    )
}

@OptIn(ExperimentalMaterialApi::class)
class BottomSheetState internal constructor(
    internal val sheetState: ModalBottomSheetState
) {
    internal val currentContent = mutableStateOf<(@Composable () -> Unit)?>(null)

    suspend fun show(title: String? = null, content: @Composable () -> Unit) {
        currentContent.value = {

            LaunchedEffect(sheetState.currentValue) {
                if (sheetState.currentValue == ModalBottomSheetValue.Hidden || currentContent.value == null) {
                    dismiss()
                }
            }

            Stack(spaceBetween = LayoutGap.none) {
                title?.takeIf { it.isNotBlank() }?.let {
                    BottomSheetHeading(it, onDismiss = {
                        currentContent.value = null
                    })
                }
                content.invoke()
            }
        }
        sheetState.show()
    }

    suspend fun dismiss() {
        sheetState.hide()
        currentContent.value = null
    }
}

@Composable
private fun BottomSheetHeading(
    title: String,
    onDismiss: () -> Unit,
    modifier: Modifier = Modifier
) {
    Tier(
        modifier = modifier.padding(
            horizontal = Theme.dimens.size_m,
            vertical = Theme.dimens.size_l
        )

    ) {
        SectionHeaderItem(title, modifier = Modifier.weight(1f))
        BodyTextLarge("X", Modifier.clickable { onDismiss() }) // TODO icon
    }
}

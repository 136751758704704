

// Do not edit directly
// Generated on Fri, 13 Oct 2023 06:39:50 GMT



package io.daio.pancake.foundations.color;

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.*

object RawColors {
  val rawChalkBlack = Color(0xff1c1c1c)
  val rawChalkBlue = Color(0xff6d83b5)
  val rawChalkBrown = Color(0xff8b4513)
  val rawChalkGreen = Color(0xff7ea77b)
  val rawChalkOrange = Color(0xffff7f50)
  val rawChalkPink = Color(0xffee6c81)
  val rawChalkPurple = Color(0xff8b77a9)
  val rawChalkRed = Color(0xffd64933)
  val rawChalkWhite = Color(0xfff4f4f4)
  val rawChalkYellow = Color(0xfff4d35e)
}

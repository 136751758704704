package io.daio.pancake.foundations.text

import androidx.compose.runtime.Immutable
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight.Companion.W400
import androidx.compose.ui.text.font.FontWeight.Companion.W700

@Immutable
data class Typography internal constructor(
    val titleScreen: TextStyle,
    val titleLarge: TextStyle,
    val titleDefault: TextStyle,
    val bodyLarge: TextStyle,
    val bodyDefault: TextStyle,
    val link: TextStyle
)

internal val mainTypography: Typography by lazy(LazyThreadSafetyMode.NONE) {
    Typography(
        titleScreen = TextStyle(
            fontSize = FontSize.fontTitleScreen,
            fontStyle = FontStyle.Normal,
            fontWeight = W700,
        ),
        titleLarge = TextStyle(
            fontSize = FontSize.fontTitleLarge,
            fontStyle = FontStyle.Normal,
            fontWeight = W700,
        ),
        titleDefault = TextStyle(
            fontSize = FontSize.fontTitleDefault,
            fontStyle = FontStyle.Normal,
            fontWeight = W700,
        ),
        bodyLarge = TextStyle(
            fontSize = FontSize.fontBodyLarge,
            fontFamily = FontFamily.SansSerif,
            fontStyle = FontStyle.Normal,
            fontWeight = W400
        ),
        bodyDefault = TextStyle(
            fontSize = FontSize.fontBodyDefault,
            fontFamily = FontFamily.SansSerif,
            fontStyle = FontStyle.Normal,
            fontWeight = W400
        ),
        link = TextStyle(
            fontSize = FontSize.fontLink,
            fontFamily = FontFamily.SansSerif,
            fontStyle = FontStyle.Normal,
        )
    )
}
package io.daio.pancake.components.image

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.unit.dp
import io.daio.pancake.components.buttons.BaseButton
import io.daio.pancake.components.buttons.PancakeButtonDefaults

@Composable
fun Avatar(
    painter: Painter,
    modifier: Modifier = Modifier,
    onClick: () -> Unit = {}
) {
    BaseButton(
        "",
        modifier = modifier.size(40.dp),
        buttonColors = PancakeButtonDefaults.colors(),
        elevation = null,
        contentPadding = PaddingValues(0.dp),
        composable = {
            Image(painter, "", Modifier.fillMaxSize())
        },
        onClick = onClick
    )
}
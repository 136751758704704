package io.daio.pancake.components.toggle

import androidx.compose.material.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import io.daio.pancake.foundations.Theme

@Composable
fun Toggle(
    checked: Boolean,
    onCheckedChange: ((Boolean) -> Unit)?,
    modifier: Modifier = Modifier,
    colors: SwitchColors = ToggleDefaults.colors()
) {
    CompositionLocalProvider(LocalElevationOverlay provides NoElevation) {
        Switch(
            checked = checked,
            onCheckedChange = onCheckedChange,
            modifier = modifier,
            colors = colors
        )
    }
}

object ToggleDefaults {
    @Composable
    fun colors(): SwitchColors = SwitchDefaults.colors(
        checkedThumbColor = Theme.colors.interactivePrimary,
        uncheckedThumbColor = Theme.colors.interactivePrimary,
        checkedTrackColor = Theme.colors.accent,
        uncheckedTrackColor = Theme.colors.interactiveSecondary
    )
}

private object NoElevation : ElevationOverlay {
    @Composable
    override fun apply(color: Color, elevation: Dp): Color {
        return color
    }
}

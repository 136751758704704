package io.daio.pancake.foundations.size

import androidx.compose.runtime.Immutable
import androidx.compose.ui.unit.Dp

@Immutable
data class Dimens internal constructor(
    val size_xxxs: Dp,
    val size_xxs: Dp,
    val size_xs: Dp,
    val size_s: Dp,
    val size_m: Dp,
    val size_l: Dp,
    val size_xl: Dp,
    val size_xxl: Dp,
    val size_xxxl: Dp,
)

internal val mainDimens = Dimens(
    size_xxxs = Spacing.spaceXxxs,
    size_xxs = Spacing.spaceXxs,
    size_xs = Spacing.spaceXs,
    size_s = Spacing.spaceS,
    size_m = Spacing.spaceM,
    size_l = Spacing.spaceL,
    size_xl = Spacing.spaceXl,
    size_xxl = Spacing.spaceXxl,
    size_xxxl = Spacing.spaceXxxl
)

package io.daio.pancake.components.text

import androidx.compose.material.TextFieldColors
import androidx.compose.material.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.disabled

object PancakeTextAreaDefaults {
    @Composable
    fun colors(): TextFieldColors = TextFieldDefaults.textFieldColors(
        backgroundColor = Color.Transparent,
        cursorColor = Theme.colors.textPrimary,
        focusedIndicatorColor = Color.Transparent,
        disabledIndicatorColor = Color.Transparent,
        errorIndicatorColor = Color.Transparent,
        unfocusedIndicatorColor = Color.Transparent,
        focusedLabelColor = Theme.colors.textSecondary,
        disabledLabelColor = Theme.colors.textSecondary.disabled(),
        errorLabelColor = Theme.colors.textSecondary,
        unfocusedLabelColor = Theme.colors.textSecondary.copy(alpha = .4f),
        leadingIconColor = Theme.colors.interactiveSecondary,
        disabledLeadingIconColor = Theme.colors.interactiveSecondary.disabled(),
        errorLeadingIconColor = Theme.colors.interactiveSecondary,
        placeholderColor = Theme.colors.interactiveSecondary,
        disabledPlaceholderColor = Theme.colors.interactiveSecondary.disabled(),
        textColor = Theme.colors.textPrimary,
        disabledTextColor = Theme.colors.textPrimary.disabled(),
        trailingIconColor = Theme.colors.textSecondary,
        disabledTrailingIconColor = Theme.colors.textSecondary.disabled(),
        errorTrailingIconColor = Theme.colors.textSecondary
    )
}

package io.daio.pancake.components.search

import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.TextField
import androidx.compose.material.TextFieldColors
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onKeyEvent
import androidx.compose.ui.unit.dp
import io.daio.pancake.components.text.PancakeTextAreaDefaults
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Tier

@Composable
fun SearchBar(
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    onTrailingIconClicked: (() -> Unit)? = null,
    onEnter: (() -> Unit)? = null,
    placeholder: @Composable () -> Unit = {
        BodyText("Search", textColor = Theme.colors.interactiveSecondary)
    },
    colors: TextFieldColors = PancakeTextAreaDefaults.colors()
) {
    Tier(
        spaceBetween = LayoutGap.small,
        modifier = modifier.border(1.dp, Theme.colors.interactiveSecondary, Theme.shapes.circle)
            .height(54.dp)
            .padding(horizontal = Theme.dimens.size_xs)
            .onKeyEvent {
                if (it.key == Key.Enter) {
                    onEnter?.invoke()
                    return@onKeyEvent true
                }

                false
            },
    ) {
        TextField(
            value,
            onValueChange,
            Modifier.fillMaxWidth(),
            colors = colors,
            textStyle = Theme.typography.bodyLarge,
            singleLine = true,
            trailingIcon = {
                BodyText("X", modifier = Modifier.clickable { onTrailingIconClicked?.invoke() })
            },
            placeholder = placeholder
        )
    }
}

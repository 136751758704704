package io.daio.pancake.components.buttons

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.size
import androidx.compose.material.ButtonColors
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack

@Composable
fun CircularAccentButton(
    text: String,
    icon: Painter,
    onClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    CircularButton(
        modifier = modifier,
        icon = icon,
        onClick = onClick,
        colors = PancakeButtonDefaults.colors(
            Theme.colors.accent,
            Theme.colors.textOnAccent
        ),
        text = text
    )
}

@Composable
fun CircularButton(
    text: String,
    icon: Painter,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    colors: ButtonColors = PancakeButtonDefaults.colors()
) {
    BaseCircularButton(
        modifier = modifier,
        icon = icon,
        onClick = onClick,
        buttonColors = colors,
        text = text
    )
}

@Composable
private fun BaseCircularButton(
    icon: Painter,
    onClick: () -> Unit,
    buttonColors: ButtonColors,
    text: String,
    modifier: Modifier = Modifier
) {
    Stack(
        spaceBetween = LayoutGap.tiny,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalAlignment = Alignment.CenterVertically
    ) {
        BaseButton(
            "",
            modifier = modifier.size(40.dp),
            buttonColors = buttonColors,
            contentPadding = PaddingValues(Theme.dimens.size_xs),
            composable = {
                Image(icon, "", Modifier.size(32.dp))
            },
            onClick = onClick
        )

        BodyText(text)
    }
}
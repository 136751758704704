package io.daio.pancake.components.tabs

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material.TabRow
import androidx.compose.material.TabRowDefaults
import androidx.compose.material.TabRowDefaults.tabIndicatorOffset
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyTextLarge

data class TabBarItem(
    val title: String
)

@Composable
fun TabBar(
    items: List<TabBarItem>,
    modifier: Modifier = Modifier,
    initialItem: Int = 0,
    onClick: (TabBarItem) -> Unit
) {
    val selectedItem = remember { mutableStateOf(initialItem) }

    TabRow(
        selectedItem.value,
        modifier = Modifier.wrapContentHeight().then(modifier),
        backgroundColor = Color.Transparent,
        contentColor = Color.Transparent,
        indicator = { tabPositions ->
            TabRowDefaults.Indicator(
                Modifier.tabIndicatorOffset(tabPositions[selectedItem.value]),
                color = Theme.colors.interactiveSecondary
            )
        },
        divider = {
            TabRowDefaults.Indicator(
                height = 1.dp,
                color = Theme.colors.divider
            )
        }
    ) {
        items.forEachIndexed { i, it ->
            BodyTextLarge(it.title,
                textAlign = TextAlign.Center,
                modifier = Modifier
                    .height(Theme.dimens.size_xl)
                    .clickable {
                        onClick(it)
                        selectedItem.value = i
                    })
        }
    }
}

package io.daio.pancake.components.sidebar

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.material.DrawerState
import androidx.compose.material.DrawerValue
import androidx.compose.material.ModalDrawer
import androidx.compose.material.rememberDrawerState
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.layout.Stack

@Composable
fun SideMenuScaffold(
    drawerState: DrawerState = rememberDrawerState(DrawerValue.Closed),
    modifier: Modifier = Modifier,
    drawerContent: @Composable ColumnScope.() -> Unit,
    content: @Composable () -> Unit
) {
    ModalDrawer(
        modifier = modifier.wrapContentWidth(),
        drawerState = drawerState,
        drawerBackgroundColor = Color.Transparent,
        drawerContent = {
            Stack(
                content = drawerContent, modifier = Modifier
                    .fillMaxWidth(.5f)
                    .fillMaxHeight()
                    .background(Theme.colors.background)
                    .padding(vertical = Theme.dimens.size_xl)
            )
        },
        drawerElevation = 0.dp,
        drawerShape = Theme.shapes.small,
        content = {
            content()
        })
}
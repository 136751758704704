@file:OptIn(ExperimentalMaterialApi::class)

package io.daio.pancake.components.scaffold

import androidx.compose.foundation.layout.*
import androidx.compose.material.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import io.daio.pancake.components.bottomsheet.BottomSheetState
import io.daio.pancake.components.bottomsheet.rememberBottomSheetState
import io.daio.pancake.components.sidebar.SideMenuScaffold
import io.daio.pancake.components.snackbar.SnackbarHolder
import io.daio.pancake.components.snackbar.SnackbarState
import io.daio.pancake.components.snackbar.rememberSnackbarState
import io.daio.pancake.foundations.PancakeTheme
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.Colors

@Composable
fun PancakeScaffold(
    modifier: Modifier = Modifier,
    themeColors: Colors = Theme.colors,
    snackbarState: SnackbarState = rememberSnackbarState(),
    bottomSheetState: BottomSheetState = rememberBottomSheetState(),
    sidebarState: DrawerState = rememberDrawerState(DrawerValue.Closed),
    sideBar: @Composable (ColumnScope.() -> Unit),
    topBar: @Composable (() -> Unit)? = null,
    bottomBar: @Composable (() -> Unit)? = null,
    content: @Composable (PaddingValues) -> Unit
) {
    PancakeTheme(themeColors = themeColors, modifier = modifier) {
        SideMenuScaffold(
            drawerState = sidebarState,
            drawerContent = sideBar
        ) {
            MainScaffolding(bottomSheetState,  snackbarState, bottomBar, topBar, content)
        }
    }
}

@Composable
fun PancakeScaffold(
    modifier: Modifier = Modifier,
    themeColors: Colors = Theme.colors,
    snackbarState: SnackbarState = rememberSnackbarState(),
    bottomSheetState: BottomSheetState = rememberBottomSheetState(),
    topBar: @Composable (() -> Unit)? = null,
    bottomBar: @Composable (() -> Unit)? = null,
    content: @Composable (PaddingValues) -> Unit
) {
    PancakeTheme(themeColors = themeColors, modifier = modifier) {
        MainScaffolding(bottomSheetState,  snackbarState, bottomBar, topBar, content)
    }
}

@OptIn(ExperimentalMaterialApi::class)
@Composable
private fun MainScaffolding(
    bottomSheetState: BottomSheetState,
    snackbarState: SnackbarState,
    bottomBar: @Composable (() -> Unit)?,
    topBar: @Composable (() -> Unit)?,
    content: @Composable (PaddingValues) -> Unit
) {
    Box {
        ModalBottomSheetLayout(
            sheetContent = { bottomSheetState.currentContent.value?.invoke() },
            scrimColor = Theme.colors.background.copy(alpha = .6f),
            sheetShape = Theme.shapes.large,
            sheetElevation = 0.dp,
            sheetContentColor = Theme.colors.background,
            sheetBackgroundColor = Theme.colors.background,
            sheetState = bottomSheetState.sheetState
        ) {
            val paddingValues =
                PaddingValues(
                    bottom = if (bottomBar != null) 72.dp else Theme.dimens.size_s,
                    top = if (topBar != null) 72.dp else Theme.dimens.size_s,
                    start = Theme.dimens.size_s,
                    end = Theme.dimens.size_s
                )
            Box {
                topBar?.let {
                    Box(modifier = Modifier.align(Alignment.TopCenter)) {
                        topBar.invoke()
                    }
                }
                content(paddingValues)
                bottomBar?.let {
                    Box(modifier = Modifier.align(Alignment.BottomCenter)) {
                        bottomBar.invoke()
                    }
                }
            }
            SnackbarHolder(
                snackbarState,
                Modifier.align(Alignment.BottomCenter)
                    .padding(Theme.dimens.size_m)
            )
        }
    }
}

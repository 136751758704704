package screens

import androidx.compose.foundation.background
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CornerBasedShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.*
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier

@Composable
fun FoundationsScreen(modifier: Modifier = Modifier) {
    Stack(
        modifier = modifier
            .fillMaxSize()
            .verticalScroll(rememberScrollState())
    ) {
        Tier(
            spaceBetween = LayoutGap.mega,
            verticalAlignment = Alignment.Top,
            modifier = Modifier.fillMaxSize().horizontalScroll(rememberScrollState())
        ) {
            Stack {
                TitleText("Typography")
                Typography()
            }
            Stack {
                TitleText("Shapes")
                Shapes()
            }
            Stack {
                TitleText("Sizes")
                Sizes()
            }
        }
    }
}

@Composable
fun Shapes() {
    Stack(modifier = Modifier.fillMaxHeight().width(IntrinsicSize.Max)) {
        SizedItem("Zero", width = 50.dp, shape = Theme.shapes.zeroRoundings)
        SizedItem("Extra Small", width = 50.dp, shape = Theme.shapes.extraSmall)
        SizedItem("Small", width = 50.dp, shape = Theme.shapes.small)
        SizedItem("Medium", width = 50.dp, shape = Theme.shapes.medium)
        SizedItem("Large", width = 50.dp, shape = Theme.shapes.large)
        SizedItem("Circle", width = 50.dp, shape = Theme.shapes.circle)
    }
}

@Composable
fun Sizes() {
    Stack(modifier = Modifier.fillMaxHeight().width(IntrinsicSize.Max)) {
        SizedItem("xxxs", width = Theme.dimens.size_xxxs)
        SizedItem("xxs", width = Theme.dimens.size_xxs)
        SizedItem("xs", width = Theme.dimens.size_xs)
        SizedItem("s", width = Theme.dimens.size_s)
        SizedItem("m", width = Theme.dimens.size_m)
        SizedItem("l", width = Theme.dimens.size_l)
        SizedItem("xl", width = Theme.dimens.size_xl)
        SizedItem("xxl", width = Theme.dimens.size_xxl)
        SizedItem("xxxl", width = Theme.dimens.size_xxxl)
    }
}

@Composable
fun SizedItem(name: String, width: Dp, height: Dp = width, shape: CornerBasedShape = Theme.shapes.zeroRoundings) {
    Tier {
        Box(
            modifier = Modifier.width(width)
                .height(height)
                .clip(shape)
                .background(Theme.colors.accent)
        )
        if (name.isNotBlank())
            BodyText(name)
    }
}

@Composable
fun Typography() {
    Stack(
        modifier = Modifier.fillMaxHeight()
            .width(IntrinsicSize.Max)
    ) {
        TitleTextScreen("Title Screen")
        TitleTextLarge("Title Large")
        TitleText("Title")
        BodyTextLarge("Body Large")
        BodyText("Body")
        LinkText("Link")
    }
}
package screens

import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import io.daio.pancake.components.buttons.SmallButton
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier

@Composable
fun LayoutScreen(modifier: Modifier = Modifier) {
    Stack(
        modifier = modifier.fillMaxSize()
    ) {
        var gap by remember { mutableStateOf(LayoutGap.medium) }

        Tier(
            modifier = Modifier.fillMaxSize().verticalScroll(rememberScrollState()),
            verticalAlignment = Alignment.Top,
            horizontalAlignment = Alignment.Start
        ) {
            Stack(
                modifier = Modifier.width(IntrinsicSize.Max),
                horizontalAlignment = Alignment.Start
            ) {
                BodyText("Gap Options:\n\n${gap.name}")
                SmallButton("None") {
                    gap = LayoutGap.none
                }
                SmallButton("Tiny") {
                    gap = LayoutGap.tiny
                }
                SmallButton("Small") {
                    gap = LayoutGap.small
                }
                SmallButton("Medium") {
                    gap = LayoutGap.medium
                }
                SmallButton("Large") {
                    gap = LayoutGap.large
                }
                SmallButton("Extra Large") {
                    gap = LayoutGap.extraLarge
                }
                SmallButton("Huge") {
                    gap = LayoutGap.huge
                }
                SmallButton("Mega") {
                    gap = LayoutGap.mega
                }
            }

            Stack(
                spaceBetween = gap,
                modifier = Modifier.weight(1f)
                    .fillMaxHeight()
                    .animateContentSize(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                BodyText("Stack")
                repeat(5) {
                    key(it) {
                        SizedItem("", width = 100.dp, height = 32.dp)
                    }
                }
            }
            Stack(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier.weight(1f)
            ) {
                BodyText("Tier")
                Tier(
                    spaceBetween = gap,
                    modifier = Modifier
                        .fillMaxSize()
                        .animateContentSize(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    repeat(5) {
                        key(it) {
                            SizedItem("", width = 32.dp, height = 100.dp)
                        }
                    }
                }
            }
        }
    }
}
package screens

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import io.daio.pancake.components.bottombar.PancakeBarItem
import io.daio.pancake.components.bottombar.PancakeBottomBar
import io.daio.pancake.components.search.SearchBar
import io.daio.pancake.components.tabs.TabBar
import io.daio.pancake.components.tabs.TabBarItem
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import pancake.web.generated.resources.Res
import pancake.web.generated.resources.logo

@OptIn(ExperimentalResourceApi::class)
@Composable
fun BarsScreen(modifier: Modifier = Modifier) {
    Stack(
        spaceBetween = LayoutGap.large,
        modifier = modifier
            .fillMaxSize()
            .verticalScroll(
                rememberScrollState()
            )
    ) {
        var search by remember { mutableStateOf("") }
        BodyText("Search Bar")
        SearchBar(search,
            modifier = Modifier.padding(horizontal = Theme.dimens.size_m),
            onValueChange = {
                search = it
            },
            onTrailingIconClicked = {
                search = ""
            },
            onEnter = {
                search = ""
            }
        )

        var currentBarItem by remember { mutableStateOf("home") }

        BodyText("Bottom Bar")
        PancakeBottomBar {
            PancakeBarItem(
                "Home",
                icon = painterResource(Res.drawable.logo),
                selected = currentBarItem.lowercase() == "home",
                modifier = Modifier.weight(1f),
                onClick = {
                    currentBarItem = "home"
                }
            )
            PancakeBarItem(
                "Stuff",
                icon = painterResource(Res.drawable.logo),
                selected = currentBarItem.lowercase() == "stuff",
                modifier = Modifier.weight(1f),
                onClick = {
                    currentBarItem = "stuff"
                }
            )
            PancakeBarItem(
                "Thing",
                icon = painterResource(Res.drawable.logo),
                selected = currentBarItem.lowercase() == "thing",
                modifier = Modifier.weight(1f),
                onClick = {
                    currentBarItem = "thing"
                }
            )
        }

        BodyText("Tab Bar")
        val tabItems = remember {
            buildList {
                add(TabBarItem("Item 1"))
                add(TabBarItem("Item 2"))
                add(TabBarItem("Item 3"))
            }
        }
        var currentTabItem by remember { mutableStateOf(tabItems.first()) }

        TabBar(tabItems,
            initialItem = tabItems.indexOf(currentTabItem),
            onClick = {

            })
    }
}
package io.daio.pancake.components.item

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.unit.dp
import io.daio.pancake.components.toggle.Toggle
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.layout.Stack

@Composable
fun ToggleItem(
    title: String,
    toggled: Boolean,
    onToggleChanged: ((Boolean) -> Unit),
    modifier: Modifier = Modifier,
    icon: Painter? = null,
    subtitle: String? = null
) {
    CustomItem(
        modifier = modifier,
        onAction = {
            onToggleChanged(!toggled)
        },
        leadingItem = {
            icon?.let {
                Image(it, "", Modifier.size(32.dp))
            }
        },
        mainContent = {
            Stack(modifier = Modifier.weight(1f)) {
                BodyTextLarge(title)
                subtitle?.let {
                    BodyText(it)
                }
            }
        }, trailingItem = {
            Toggle(toggled, onToggleChanged)
        }
    )
}
package io.daio.pancake.components.snackbar

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.layout.Tier

@Composable
fun Snackbar(
    value: String,
    modifier: Modifier = Modifier,
) {
    Tier(
        horizontalAlignment = Alignment.Start,
        modifier = modifier.background(Theme.colors.textPrimary, Theme.shapes.large)
            .heightIn(min = 56.dp)
            .fillMaxWidth()
            .padding(Theme.dimens.size_s)
    ) {
        BodyTextLarge(value, textColor = Theme.colors.textComponent)
    }
}
package io.daio.pancake.components.toggle

import androidx.compose.material.Checkbox
import androidx.compose.material.CheckboxColors
import androidx.compose.material.CheckboxDefaults
import androidx.compose.material.ContentAlpha
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import io.daio.pancake.foundations.Theme

@Composable
fun Check(
    checked: Boolean,
    onCheckedChange: ((Boolean) -> Unit)?,
    modifier: Modifier = Modifier,
    colors: CheckboxColors = CheckDefaults.colors()
) {
    Checkbox(
        checked,
        onCheckedChange,
        modifier,
        colors = colors
    )
}

@Composable
fun AccentCheck(
    checked: Boolean,
    modifier: Modifier = Modifier,
    onCheckedChange: ((Boolean) -> Unit)?
) {
    Check(
        checked = checked,
        modifier = modifier,
        colors = CheckDefaults.colors(Theme.colors.accent),
        onCheckedChange = onCheckedChange
    )
}

object CheckDefaults {
    @Composable
    fun colors(checkedColor: Color = Theme.colors.interactivePrimary): CheckboxColors = CheckboxDefaults.colors(
        checkedColor = checkedColor,
        uncheckedColor = Theme.colors.interactiveSecondary,
        checkmarkColor = Theme.colors.textComponent,
        disabledColor = Theme.colors.interactiveSecondary.copy(alpha = ContentAlpha.disabled),
        disabledIndeterminateColor = Theme.colors.textComponent.copy(alpha = ContentAlpha.disabled)
    )
}

package io.daio.pancake.components.item

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack

@Composable
fun SectionHeaderItem(
    text: String,
    modifier: Modifier = Modifier
) {
    Stack(
        modifier.height(48.dp).background(Theme.colors.background),
        spaceBetween = LayoutGap.tiny,
        verticalAlignment = Alignment.CenterVertically
    ) {
        BodyTextLarge(
            text,
            textAlign = TextAlign.Start,
            modifier = Modifier
                .padding(horizontal = Theme.dimens.size_xs)
                .fillMaxWidth()
        )
        Divider(color = Theme.colors.divider)
    }
}

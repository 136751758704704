package io.daio.pancake.components.bottombar

import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.material3.BottomAppBar
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier

@Composable
fun PancakeBottomBar(
    modifier: Modifier = Modifier,
    actions: @Composable RowScope.() -> Unit
) {
    BottomAppBar(
        containerColor = Theme.colors.background,
        contentColor = Theme.colors.background
    ) {
        Tier(
            modifier = modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalAlignment = Alignment.CenterVertically
        ) {
            actions()
        }
    }
}

@Composable
fun RowScope.PancakeBarItem(
    title: String,
    icon: Painter?,
    selected: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    barItemColors: PancakeBarItemColors = PancakeBarItemDefaults.colors()
) {
    val scale by animateFloatAsState(targetValue = if (selected) 1.2f else 1f)

    Stack(
        spaceBetween = LayoutGap.tiny,
        verticalAlignment = Alignment.CenterVertically,
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = modifier.clickable(onClick = onClick).height(56.dp).fillMaxWidth()
    ) {
        icon?.let {
            Image(
                icon,
                contentDescription = "Bottom Bar Item $title",
                modifier = Modifier.graphicsLayer {
                    scaleX = scale
                    scaleY = scale
                }.size(24.dp)
            )
        }
        LinkText(
            title,
            textColor = barItemColors.textColor(selected).value
        )
    }
}


@Stable
private class DefaultPancakeBarItemColors(
    private val textColor: Color,
    private val selectedTextColor: Color
) : PancakeBarItemColors {
    @Composable
    override fun textColor(selected: Boolean): State<Color> {
        val color = if (selected) selectedTextColor else textColor
        return animateColorAsState(color)
    }
}

object PancakeBarItemDefaults {
    @Composable
    fun colors(
        textColor: Color = Theme.colors.textPrimary,
        selectedTextColor: Color = Theme.colors.accent
    ): PancakeBarItemColors {
        return remember(textColor, selectedTextColor) {
            DefaultPancakeBarItemColors(
                textColor,
                selectedTextColor
            )
        }
    }
}

@Stable
interface PancakeBarItemColors {
    @Composable
    fun textColor(selected: Boolean): State<Color>
}

package io.daio.pancake.layout

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme

enum class LayoutGap(val size: Dp) {
    none(0.dp),
    tiny(Theme.dimens.size_xs),
    small(Theme.dimens.size_s),
    medium(Theme.dimens.size_m),
    large(Theme.dimens.size_l),
    extraLarge(Theme.dimens.size_xl),
    huge(Theme.dimens.size_xxl),
    mega(Theme.dimens.size_xxxl)
}
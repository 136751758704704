package io.daio.pancake.components.item

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.layout.Tier

@Composable
fun CustomItem(
    mainContent: @Composable RowScope.() -> Unit,
    modifier: Modifier = Modifier,
    shape: RoundedCornerShape = Theme.shapes.large,
    leadingItem: (@Composable () -> Unit)? = null,
    trailingItem: (@Composable () -> Unit)? = null,
    onAction: (() -> Unit)? = null
) {

    Tier(
        horizontalAlignment = Alignment.Start,
        modifier = modifier
            .heightIn(min = 80.dp)
            .padding(horizontal = Theme.dimens.size_m)
            .graphicsLayer {
                this.shape = shape
                clip = true
            }
            .clickable { onAction?.invoke() }
    ) {
        leadingItem?.let { leading ->
            Box(Modifier.padding(start = Theme.dimens.size_m)) {
                leading()
            }
        }

        mainContent()

        trailingItem?.let { trailing ->
            Box(Modifier.padding(end = Theme.dimens.size_m)) {
                trailing()
            }
        }
    }
}

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.DrawerValue
import androidx.compose.material.rememberDrawerState
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.BitmapPainter
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.CanvasBasedWindow
import app.softwork.routingcompose.BrowserRouter
import app.softwork.routingcompose.Router
import io.daio.pancake.components.image.Avatar
import io.daio.pancake.components.item.NavigationItem
import io.daio.pancake.components.scaffold.PancakeScaffold
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.foundations.text.TitleTextScreen
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.Resource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.skiko.wasm.onWasmReady
import pancake.web.generated.resources.Res
import pancake.web.generated.resources.logo
import screens.*

@OptIn(ExperimentalComposeUiApi::class, ExperimentalResourceApi::class)
fun main() {
    onWasmReady {
        CanvasBasedWindow("Pancake") {
            BrowserRouter(initPath = "/") {
                val router = Router.current
                val sideBarState = rememberDrawerState(DrawerValue.Closed)
                val scope = rememberCoroutineScope()

                PancakeScaffold(
                    sideBar = {
                    NavigationItem("Foundations") {
                        router.navigate(to = "/")
                        scope.launch {
                            sideBarState.close()
                        }
                    }
                    NavigationItem("Layout") {
                        router.navigate(to = "/layout")
                        scope.launch {
                            sideBarState.close()
                        }
                    }
                    NavigationItem("Buttons") {
                        router.navigate(to = "/buttons")
                        scope.launch {
                            sideBarState.close()
                        }
                    }
                    NavigationItem("Checkables") {
                        router.navigate(to = "/checkables")
                        scope.launch {
                            sideBarState.close()
                        }
                    }
                    NavigationItem("List Items") {
                        router.navigate(to = "/list-items")
                        scope.launch {
                            sideBarState.close()
                        }
                    }
                    NavigationItem("Bars") {
                        router.navigate(to = "/bars")
                        scope.launch {
                            sideBarState.close()
                        }
                    }
                },
                    sidebarState = sideBarState,
                    topBar = {
                        Tier(
                            horizontalAlignment = Alignment.Start,
                            modifier = Modifier.fillMaxWidth()
                                .padding(Theme.dimens.size_m)
                        ) {
                            Avatar(
                                modifier = Modifier.size(36.dp),
                                onClick = {
                                    scope.launch {
                                        sideBarState.open()
                                    }
                                },
                                painter =  painterResource(Res.drawable.logo),
                            )
                            BodyText("Pancake")
                        }
                    }) {
                    Stack(
                        modifier = Modifier.padding(
                            top = it.calculateTopPadding(),
                            start = Theme.dimens.size_m
                        ),
                        spaceBetween = LayoutGap.medium
                    ) {
                        route("/") {
                            TitleTextScreen("Foundations")
                            FoundationsScreen()
                        }
                        route("/layout") {
                            TitleTextScreen("Layout")
                            LayoutScreen()
                        }
                        route("/buttons") {
                            TitleTextScreen("Buttons")
                            ButtonsScreen()
                        }
                        route("/checkables") {
                            TitleTextScreen("Checks and Toggles")
                            CheckablesScreen()
                        }
                        route("/list-items") {
                            TitleTextScreen("List Items")
                            ListItemsScreen()
                        }
                        route("/bars") {
                            TitleTextScreen("Bars")
                            BarsScreen()
                        }
                    }
                }
            }
        }
    }
}

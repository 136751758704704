package io.daio.pancake.components.toggle

import androidx.compose.material.ContentAlpha
import androidx.compose.material.RadioButton
import androidx.compose.material.RadioButtonColors
import androidx.compose.material.RadioButtonDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import io.daio.pancake.foundations.Theme

@Composable
fun Radio(
    selected: Boolean,
    onSelectedChanged: ((Boolean) -> Unit)?,
    modifier: Modifier = Modifier,
    colors: RadioButtonColors = RadioDefaults.colors()
) {
    RadioButton(
        selected = selected,
        onClick = {
            onSelectedChanged?.invoke(!selected)
        },
        modifier = modifier,
        colors = colors
    )
}

@Composable
fun AccentRadio(
    selected: Boolean,
    onSelectedChanged: ((Boolean) -> Unit)?,
    modifier: Modifier = Modifier
) {
    RadioButton(
        selected = selected,
        onClick = {
            onSelectedChanged?.invoke(!selected)
        },
        modifier = modifier,
        colors = RadioDefaults.colors(Theme.colors.accent)
    )
}

object RadioDefaults {
    @Composable
    fun colors(selectedColor: Color = Theme.colors.interactivePrimary) = RadioButtonDefaults.colors(
        selectedColor = selectedColor,
        unselectedColor = Theme.colors.interactiveSecondary,
        disabledColor = Theme.colors.interactiveSecondary.copy(alpha = ContentAlpha.disabled)
    )
}


package screens

import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import io.daio.pancake.components.image.Avatar
import io.daio.pancake.components.item.*
import io.daio.pancake.components.loading.LoadingIndicator
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import pancake.web.generated.resources.Res
import pancake.web.generated.resources.logo

@OptIn(ExperimentalResourceApi::class)
@Composable
fun ListItemsScreen(modifier: Modifier = Modifier) {
    Stack(
        modifier = modifier
            .fillMaxSize()
            .verticalScroll(
                rememberScrollState()
            ),
        spaceBetween = LayoutGap.large
    ) {
        SectionHeaderItem("Section Header Item")
        NavigationItem("Navigation Item")
        var checked by remember { mutableStateOf(false) }

        CheckItem(
            title = "Check Item",
            subtitle = "Optional Subtitle",
            check = checked,
            onCheckedChange = {
                checked = it
            })

        RadioItem(
            title = "Radio Item",
            subtitle = "Optional Subtitle",
            selected = checked,
            onSelectedStateChanged = {
                checked = it
            })

        ToggleItem(
            title = "Toggle Item",
            subtitle = "Optional Subtitle",
            toggled = checked,
            onToggleChanged = {
                checked = it
            })

        CustomItem(leadingItem = {
            val image = painterResource(Res.drawable.logo)
            Avatar(painter = image)
        }, mainContent = {
            Tier(modifier = Modifier.weight(1f)) {
                BodyText("Custom Item")
            }
        }, trailingItem = {
            LoadingIndicator(modifier.fillMaxHeight(fraction = .8f))
        })
    }
}
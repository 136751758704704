package io.daio.pancake.foundations.color

import androidx.compose.material.ContentAlpha
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.ui.graphics.Color

data class Colors(
    /**
     * TEXT
     */
    val textPrimary: Color,
    val textSecondary: Color,
    val textTertiary: Color,
    /**
     * Used on interactive components like button text and snackbar text..
     */
    val textComponent: Color,
    val textLink: Color,
    val textOnAccent: Color,

    /**
     * Used on interactive elements like buttons and clickable widgets.
     */
    val interactivePrimary: Color,
    val interactiveSecondary: Color,

    val negative: Color,
    val positive: Color,
    val warning: Color,
    val default: Color,

    val divider: Color,
    val accent: Color,
    val background: Color,
    val light: Boolean
)

val lightColors = Colors(
    textPrimary = RawColors.rawChalkBlack,
    textSecondary = RawColors.rawChalkBlack.copy(alpha = .8f),
    textTertiary = RawColors.rawChalkBlack.copy(alpha = .6f),
    textComponent = RawColors.rawChalkWhite,
    textLink = RawColors.rawChalkBlue,
    textOnAccent = RawColors.rawChalkBlack,

    interactivePrimary = RawColors.rawChalkBlack,
    interactiveSecondary = RawColors.rawChalkBlack.copy(alpha = .6f),

    divider = RawColors.rawChalkBlack.copy(alpha = .1f),

    negative = RawColors.rawChalkRed,
    positive = RawColors.rawChalkGreen,
    warning = RawColors.rawChalkYellow,
    default = RawColors.rawChalkBlue,

    accent = RawColors.rawChalkBlue,
    background = RawColors.rawChalkWhite,
    light = true
)

val darkColors = Colors(
    textPrimary = RawColors.rawChalkWhite,
    textSecondary = RawColors.rawChalkWhite.copy(alpha = .8f),
    textTertiary = RawColors.rawChalkWhite.copy(alpha = .6f),
    textComponent = RawColors.rawChalkBlack,
    textLink = RawColors.rawChalkOrange,
    textOnAccent = RawColors.rawChalkBlack,

    interactivePrimary = RawColors.rawChalkWhite,
    interactiveSecondary = RawColors.rawChalkWhite.copy(alpha = .6f),

    negative = RawColors.rawChalkRed,
    positive = RawColors.rawChalkGreen,
    warning = RawColors.rawChalkYellow,
    default = RawColors.rawChalkBlue,

    divider = RawColors.rawChalkWhite.copy(alpha = .1f),

    accent = RawColors.rawChalkOrange,
    background = RawColors.rawChalkBlack,
    light = false
)

@Composable
fun Color.disabled() = copy(alpha = ContentAlpha.disabled)

@Composable
fun Color.asState() = rememberUpdatedState(this)

@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package pancake.web.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi

@ExperimentalResourceApi
private object Drawable0 {
  public val logo: DrawableResource = org.jetbrains.compose.resources.DrawableResource(
        "drawable:logo",
          setOf(
            org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/logo.png"),
          )
      )
}

@ExperimentalResourceApi
internal val Res.drawable.logo: DrawableResource
  get() = Drawable0.logo

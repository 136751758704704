package io.daio.pancake.components.loading

import androidx.compose.material.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme

@Composable
fun LoadingIndicator(modifier: Modifier = Modifier) {
    CircularProgressIndicator(
        modifier,
        strokeCap = StrokeCap.Round,
        strokeWidth = 1.dp,
        color = Theme.colors.interactivePrimary
    )
}
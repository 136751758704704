package screens

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import io.daio.pancake.components.toggle.Check
import io.daio.pancake.components.toggle.Radio
import io.daio.pancake.components.toggle.Toggle
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.foundations.text.TitleTextScreen
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier

@Composable
fun CheckablesScreen(modifier: Modifier = Modifier) {
    Stack(
        modifier = modifier
            .fillMaxSize()
            .verticalScroll(
                rememberScrollState()
            )
    ) {
        Tier {
            var toggled by remember { mutableStateOf(false) }
            var radio by remember { mutableStateOf(false) }
            var check by remember { mutableStateOf(false) }
            Stack {
                LinkText("Toggle")
                Toggle(toggled, onCheckedChange = {
                    toggled = it
                })
            }
            Stack {
                LinkText("Radio")
                Radio(radio, onSelectedChanged = {
                    radio = it
                })
            }
            Stack {
                LinkText("Check")
                Check(check, onCheckedChange = {
                    check = it
                })
            }
        }
    }
}
package io.daio.pancake.foundations.text

import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.text.BasicText
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.style.TextAlign

@Composable
internal fun BaseText(
    text: String,
    textStyle: TextStyle,
    modifier: Modifier = Modifier,
    textAlign: TextAlign = TextAlign.Start
) {
    BasicText(
        text,
        modifier.wrapContentHeight(),
        style = textStyle.merge(textAlign = textAlign)
    )
}